.route-select-time {
  width: 180px;
  position: relative;
  z-index: var(--popup-z-index);

  &__value-container {
    padding-left: 5px !important;
  }

  &__control--is--focused {
    border: none !important;
    outline: none !important;
  }

  &__control--is--selected {
    border: none !important;
    outline: none !important;
  }

  &__control--menu-is-open {
    border: none !important;
    outline: none !important;
  }

  &-indicator {
    & > i {
      font-size: 10px;
    }
  }

  .dtm-select {
    &__control {
      border: 0.5px solid var(--border-color-default) !important;

      &--is-focused {
        border-color: var(--border-color-hover) !important;
      }
    }
  }
}
