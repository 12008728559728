.route-history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 5px 15px;

  &:hover {
    background-color: var(--background-color-hover);
    cursor: pointer;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &-left {
    display: flex;
  }

  &-last-icon {
    & > i {
      font-size: 20px;
      color: var(--text-color-default);
    }

    padding-left: 0;
  }

  &-delete-button {
    background-color: transparent;
    border: none;
    position: relative;
    left: 3px;

    &:hover {
      cursor: pointer;
    }

    & > i {
      color: var(--text-color-default);
      font-size: 12px;
    }
  }

  &-label {
    padding-left: 17px;
    color: var(--text-color-default);
    line-height: 35px;
    max-width: 235px;
    display: flex;
    align-items: center;
  }

  &-address {
    color: var(--text-color-main);
    max-width: 225px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
