.eat {
  display: block;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #747474;

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    gap: 8px;
    margin-bottom: 12px;
  }

  &__title {
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
  }

  &__body {
    display: block;
  }

  &__attribute {
    display: grid;
    grid-template-columns: 100px 100px 40px;
    gap: 4px;
    margin-top: 6px;
    padding-top: 6px;
    border-top: 1px solid #d9d9d9;
  }

  &__label {
    display: block;
    width: 100px;
    height: 24px;
    line-height: 16px;
  }

  &__value {
    display: block;
    width: 100px;
    height: 24px;
    line-height: 16px;
  }

  &__actions {
    display: grid;
    height: 24px;
    grid-template-columns: 24px 24px;
    gap: 8px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }

    &-add {
      grid-column: 2;
      svg {
        fill: #62c400;
      }
    }

    &-edit {
      svg {
        fill: #42a5f5;
      }
    }

    &-remove {
      svg {
        fill: #fd1c00;
      }
    }

    &__icon {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}
