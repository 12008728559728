.dtm-map-compass {
  position: relative;
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;

  &__outer,
  &__inner {
    position: absolute;
    display: grid;
    place-items: center;
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    border-radius: 50%;
  }

  &__outer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 6.32px solid #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.102);

    &__triangle {
      position: absolute;
      display: grid;
      height: 135%;
      width: 6px;
      padding-top: 1px;

      svg {
        fill: #ff5e5e;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__inner {
    width: 24px;
    height: 24px;
    background-color: #ffffff;

    svg {
      width: 16px;
      height: 16px;
      fill: #747474;
    }

    &_volumetric {
      background-color: var(--background-color-active);

      svg {
        fill: #ffffff;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
