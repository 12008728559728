.details-administrative-division {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  padding-right: 0;
  width: 680px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  border-radius: 4px;
}
