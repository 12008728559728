.sector-analysis-tooltip {
  padding: 12px 12px 16px 12px;
  width: 220px;
  background-color: var(--background-color-default);
  box-shadow: var(--box-shadow-popup);
  border-radius: 3px;
  position: relative;

  &-header {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--text-color-main);
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    & > svg {
      color: var(--green-main);
      fill: var(--green-main);
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }
  }

  &-row {
    display: flex;
    padding-top: 6px;
    justify-content: space-between;
  }

  &-address {
    font-size: 12px;
    line-height: 1.5;
    color: var(--text-color-second);
  }

  &-name {
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--text-color-default);
  }

  &-value {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--text-color-main);
  }

  &-range {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--text-color-default);
  }

  &-close {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
