.filter-roads {
  left: 1095px;
  display: flex;
  border-radius: 25px;
  box-shadow: var(--box-shadow-button);
  background-color: var(--background-color-default);
  overflow: hidden;
  height: 40px;
  padding: 0 10px;
  transition: all 0.3s linear;

  &__item {
    width: 40px;
  }
}
