.external-system-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color-default);
  width: 100%;
  padding: 5px 16px;
  color: var(--text-color-default);

  &:hover {
    color: var(--text-color-hover);
    background-color: var(--background-color-hover);
  }

  > .external-system-icon {
    margin-left: -6px;
  }

  &-name {
    font-size: 14px;
    width: 245px;
  }

  &-checkbox {
    & .route-checkbox-container {
      padding: 0;
      position: relative;
      top: 1px;
      right: 7px;
    }
  }

  &-disabled {
    .external-system-item-name {
      color: var(--text-color-disabled);
    }
  }
}
