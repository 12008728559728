.input-autosuggest {
  position: relative;

  &-item {
    display: block;
    max-width: 210px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    & > i {
      padding-right: 10px;
      font-size: 18px;
    }

    & .dtm-last-address-icon {
      font-size: 16px;
    }
  }

  &-clear-button {
    position: absolute;
    top: 9px;
    right: 9px;
    padding-left: 4px;
    background-color: var(--background-color-default);
  }

  .react-autosuggest {
    &__input {
      border-radius: 3px;
      border: 1px solid var(--border-color-default);
      padding: 8px;
      width: 100%;
      font-size: 12px;
      padding-right: 18px;

      &:hover {
        border-color: var(--border-color-hover);
      }

      &--focused {
        border-color: var(--border-color-focus);

        &:hover {
          border-color: var(--border-color-focus);
        }
      }
    }

    &__suggestions {
      &-container {
        position: absolute;
        background-color: var(--background-color-default);
        z-index: var(--popup-z-index);
        box-shadow: var(--box-shadow-popup);
        width: 100%;

        &--open {
          padding: 10px 0;
        }
      }

      &-list {
        max-height: 210px;
        overflow: auto;
      }
    }

    &__suggestion {
      color: var(--text-color-default);
      padding: 5px;

      &--highlighted {
        background-color: var(--background-color-hover);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-failed {
    color: var(--text-color-error);

    .react-autosuggest {
      &__input {
        border: 1px solid var(--border-color-error);
      }
    }
  }
}
