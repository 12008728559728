.route-length-error {
  display: flex;
  flex-direction: column;

  &__block {
    display: flex;
    justify-content: center;
    color: var(--text-color-error);
  }

  &__header {
    font-weight: 600;
  }
}
