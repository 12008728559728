@keyframes rotate {
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dashoffset: -125;
  }
}

.loading-component {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(0deg);
  margin: auto;
  width: 20px;
  height: 20px;
  animation: rotate 1.5s ease infinite;
  transform-origin: center;
  z-index: var(--background-z-index);

  &-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.07);
  }

  > svg {
    display: block;
    margin: auto;
    width: 20px;
    height: 20px;
    fill: transparent;
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
    stroke-width: 2px;
    stroke: #8ec94a;
    animation: dash 3.5s ease-out infinite;
  }

  &_large {
    width: 40px;
    height: 40px;

    & > svg {
      width: 40px;
      height: 40px;
    }
  }
}
