.details-administrative-division__highways-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &__title {
    font-weight: bolder;
    padding-bottom: 8px;
  }

  &__list {
    height: 96px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
