.sector-analysis-diagram-legend {
  display: flex;

  &__header {
    display: flex;
    margin-right: 24px;
  }

  &__content {
    display: flex;
  }

  &__percentage-column {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 24px;
    }

    &__circle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #e5e5e5;
      margin-right: 10px;
    }
  }

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #747474;
  }
}
