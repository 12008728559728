.system-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color-default);
  width: 100%;
  padding: 13px 16px;
  color: var(--text-color-default);

  &:hover {
    color: var(--text-color-hover);
    background-color: var(--background-color-hover);
  }

  > .system-icon {
    margin-left: -6px;
  }

  &-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 600;
    width: 260px;
  }

  &-checkbox {
    & .route-checkbox-container {
      padding: 0;
      position: relative;
      top: 1px;
      right: 7px;
    }
  }

  &-disabled {
    .system-item-name {
      color: var(--text-color-disabled);
    }
  }
}
