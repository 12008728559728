.group-selector-popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 220px;
  padding: 12px 12px 16px 12px;
  background-color: var(--background-color-default, #fff);
  box-shadow: var(--box-shadow-popup);
  border-radius: 3px;
  user-select: text;

  &__header {
    display: flex;
    flex-grow: 0;
    justify-content: space-between;
    color: var(--text-color-main);
    align-items: center;

    &__time {
      display: flex;
      align-items: center;

      > span {
        margin-left: 12px;
      }

      > svg {
        fill: var(--icon-color-default);
        width: 18px;
        height: 18px;
        margin-right: 12px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 4px;
  }

  &__row {
    display: flex;
    padding-top: 6px;

    &__title {
      display: block;
      width: 115px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      color: var(--text-color-default);
    }

    &__value {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      color: var(--text-color-main);
    }
  }
}
