.ctrl-idz {
  height: 40px;
  padding: 7px 0 7px 7px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &__value,
  &__prediction {
    > svg {
      display: block;
    }
  }
}
