.route-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 11px;

  &-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--text-color-default);
  }

  &-button {
    background-color: transparent;
    border: none;

    &-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: var(--text-color-default);

      & > i {
        font-size: 24px;
        padding-right: 10px;
      }
    }

    &:hover {
      cursor: pointer;
      color: var(--text-color-hover);
    }

    & > i {
      color: inherit;
    }
  }
}
