.full-screen-button {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
}
