.route-transport-type {
  padding: 15px;
  display: flex;
  border-bottom: 1px solid #e0e0e0;

  &__item {
    width: 32px;
    height: 32px;
    background-color: var(--background-color-second);
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--icon-color-default);

    &:hover {
      color: var(--icon-color-hover);
      cursor: pointer;
    }

    &:not(:first-child) {
      margin-left: 10px;
    }

    &_disabled {
      &:hover {
        color: var(--icon-color-default);
        cursor: default;
      }
    }

    &_active {
      background-color: var(--background-color-active);
      color: var(--icon-color-active);
      cursor: default;

      &:hover {
        color: var(--icon-color-active);
      }

      &_disabled {
        &:hover {
          color: var(--icon-color-active);
        }
      }
    }
  }
}
