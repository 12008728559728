.sector-analysis-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 40px;
  padding: 10px 16px 11px;
  border-bottom: 1px solid var(--separator-color-default);

  &__header-info {
    display: flex;
    max-width: calc(100% - 60px);
  }

  &__title {
    white-space: nowrap;
    font-size: 14px;
    flex-basis: 102px;
    color: var(--text-color-default);
  }

  &__route {
    flex-basis: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    margin-left: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--text-color-main);

    &-compare {
      max-width: 1300px;
    }
  }

  &__controls {
    display: flex;
    margin-right: 3px;
  }

  &__close-button {
    margin-left: 10px;
  }
}
