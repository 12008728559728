.route-button-add {
  background-color: var(--background-color-default);
  border: none;
  display: flex;
  align-items: center;
  color: var(--text-color-default);

  &:hover {
    cursor: pointer;
    color: var(--text-color-hover);
  }

  &_disabled {
    &:hover {
      cursor: default;
      color: var(--text-color-default);
    }
  }

  &-label {
    padding-left: 16px;
    font-weight: 500;
    font-size: 12px;
    color: inherit;
  }

  & > i {
    font-size: 22px;
    color: inherit;
  }
}
