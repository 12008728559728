.route-maneuver {
  &__button {
    width: 100%;
    padding: 10px 15px;
    background-color: var(--background-color-default);
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &:hover {
      background-color: var(--background-color-hover);
    }
  }

  &__body {
    width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  &__instruction {
    text-align: start;
  }

  &__length {
    color: var(--text-color-default);
    font-size: 12px;
  }
}
