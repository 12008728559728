.route-time-variants {
  &-body {
    padding-bottom: 15px;
  }

  &-header {
    padding: 15px;
    cursor: pointer;
  }

  &-label {
    font-size: 12px;
    color: var(--text-color-default);
    font-weight: 500;
  }

  &-button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    & > i {
      padding-left: 10px;
      color: var(--text-color-default);
      font-size: 8px;
    }
  }

  &-body-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 0 15px;
  }

  &-interval {
    display: flex;
    align-items: center;

    & > span {
      font-size: 12px;
      color: var(--text-color-default);
      padding-right: 5px;
    }
  }
}
