.details-administrative-division__header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;

  &__heading {
    display: flex;
    gap: 8px;
    align-items: center;
    &__title {
      font-weight: bolder;
    }
  }
}
