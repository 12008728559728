.route-favorite-info {
  position: fixed;
  left: 360px;
  background-color: var(--background-color-default);
  width: 260px;
  color: var(--text-color-default);
  border-radius: 3px;
  box-shadow: var(--box-shadow-popup);

  &-header {
    display: flex;
    justify-content: space-between;
  }

  &-close {
    background-color: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }

    & > i {
      color: var(--text-color-default);
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
  }

  &-button {
    border: none;
    background: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  &-confirm {
    color: var(--green-color);
  }

  &-cancel {
    color: var(--text-color-default);
  }

  &-address {
    padding-top: 6px;
  }
}
