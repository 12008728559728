.details-administrative-division__general-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &__dimension {
    position: relative;
  }
}
