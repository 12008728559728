.container-active-focus {
  color: #747474;
  font-size: 11px;
  line-height: 16px;
  width: 95px;
  height: 32px;

  &:hover {
    cursor: pointer;
    color: #000000;
  }
}

.container-active-focus-input {
  display: none;
}
