.city-events-popup {
  --inner-blocks-width: calc(100% - 20px);

  min-width: 600px;
  box-sizing: border-box;

  &__header {
    display: flex;
    width: var(--inner-blocks-width);
  }

  &__content {
    width: var(--inner-blocks-width);
    margin-top: 10px;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-weight: 500;
    font-size: 14px;
    user-select: text;
  }

  &__info-row {
    display: flex;
    width: 100%;
    user-select: text;
  }

  &__label {
    margin-right: 10px;
    font-weight: 500;
    font-size: 13px;
  }

  &__text {
    font-size: 12px;
  }

  &__description {
    margin-top: 5px;
    font-size: 12px;
    user-select: text;
  }

  & .mapboxgl-popup-content {
    background-color: var(--background-color-default);
    color: var(--text-color-main);
    box-shadow: var(--box-shadow-popup);
    padding: 10px 10px;
    box-sizing: border-box;
  }
}
