.sector-analysis-appearance-slider {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  &__stopper {
    position: absolute;
    width: 2px;
    height: 10px;
    top: 0;
    left: 0;
    background-color: #868686;
  }

  &__labels {
    position: relative;
    display: flex;
    flex-grow: 1;
    margin-top: 9px;

    &_speed {
      margin-top: 0;
      margin-bottom: 8px;
    }
  }

  &__label {
    position: absolute;
    left: 0;
    min-width: 100px;
    z-index: 999999;

    label {
      text-align: center;
      font: bold bold 14px "Roboto";
      font-weight: bold;
      line-height: 16px;
      color: #000000;
      padding: 3px;
      border-radius: 5px;
    }
  }

  .rc-slider {
    &-handle {
      margin-top: -8.5px;
    }

    > .rc-slider-handle-1,
    > .rc-slider-handle-6 {
      pointer-events: none;
      opacity: 0;
    }
  }
}
