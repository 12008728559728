.graph-compare-container {
  position: absolute;
  width: 870px;
  background-color: var(--background-color-default);
  box-shadow: var(--box-shadow-popup);
  border-radius: 3px;
  left: 50%;
  bottom: 32px;
  transform: translateX(-50%);
  min-width: 650px;
}
