.score-icon {
  width: 20px;
  height: 20px;

  &_disabled {
    color: gray;
  }

  &-0,
  &-1,
  &-2 {
    color: var(--score-1);
  }

  &-3 {
    color: var(--score-3);
  }

  &-4,
  &-5 {
    color: var(--score-4);
  }

  &-6 {
    color: var(--score-6);
  }

  &-7,
  &-8 {
    color: var(--score-7);
  }

  &-9,
  &-10 {
    color: var(--score-9);
  }
}
