.travel-heatmap-legend {
  position: absolute;
  bottom: 16px;
  right: 16px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--background-color-default);
  box-shadow: var(--box-shadow-popup);
  border-radius: 100px;

  &__handle {
    position: absolute;
    top: -6.5px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: var(--box-shadow-popup);
    z-index: 999999;

    &:hover {
      cursor: col-resize;
    }
  }

  &__colors {
    position: relative;
    display: flex;

    :nth-child(1) {
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }

    :nth-child(9) {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }

  &__colorized-block {
    width: 50px;
    height: 8px;

    &-1 {
      background-color: #d5e2ef;
    }

    &-2 {
      background-color: #c2d4e6;
    }

    &-3 {
      background-color: #afc4de;
    }

    &-4 {
      background-color: #99b7d6;
    }

    &-5 {
      background-color: #85a8ce;
    }

    &-6 {
      background-color: #719ac6;
    }

    &-7 {
      background-color: #5d8bbe;
    }

    &-8 {
      background-color: #487db6;
    }

    &-9 {
      background-color: #346ead;
    }
  }
}
