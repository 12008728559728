.navigation-groupe-layers {
  display: flex;
  background-color: var(--background-color-default);
  box-shadow: var(--box-shadow-button);
  border-radius: var(--border-radius-popup);
  height: 40px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &__icon {
    color: var(--icon-color-default);
    fill: var(--icon-color-default);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;

    &_active {
      color: var(--background-color-active);
      fill: var(--background-color-active);
    }
  }

  &__items {
    display: flex;
    transition: width 0.3s linear;
    overflow: hidden;
  }

  &__item {
    &:not(:last-child) {
      &:after {
        content: "";
        border-right: 1px solid;
        height: 20px;
        position: relative;
        left: 8px;
        border-color: var(--border-color-default);
      }
    }
  }
}
