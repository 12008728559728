.ctrl-isochrone {
  position: absolute;
  background-color: var(--background-color-default);
  top: 49px;
  left: 342px;
  width: 150px;
  padding: 11px 19px;
  border-radius: 3px;
  box-shadow: var(--box-shadow-popup);

  &-item {
    padding: 8px 0;
    font-size: 14px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
