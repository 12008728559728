.sector-details-tooltip {
  background-color: #fff;
  user-select: text;
  padding: 12px 12px 16px 12px;
  background-color: var(--background-color-default);
  box-shadow: var(--box-shadow-popup);
  border-radius: 3px;

  &__header {
    display: grid;
    grid-template-columns: 24px 1fr 24px;
  }

  &__icon {
    display: flex;
    align-items: center;
    width: 24px;
  }

  &__time-icon {
    width: 18px;
    height: 18px;
  }

  &__title {
    display: flex;
    align-items: center;
    min-height: 24px;
  }

  &__period {
    margin-top: 3px;
  }

  &__period &__row,
  &__info &__row {
    display: grid;
    grid-template-columns: 115px 1fr;
  }

  &__label {
    line-height: 22px;
  }
  &__name {
    color: var(--text-color-default);
    line-height: 22px;
  }

  &__info,
  &__statistic {
    margin-top: 7px;
  }

  &__template {
    font-size: 13px;
    font-weight: bold;
    line-height: 24px;
  }

  &__item {
    display: grid;
    grid-template-columns: 120px 48px 48px 48px;
    &_full {
      font-weight: bold;
    }
  }
}
