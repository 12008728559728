.weekday-selector {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 32px;
  background-color: #f5f5f5;
  color: var(--text-color-default);
  border: none;
  border-radius: 4px;

  &:active {
    box-shadow: 0 0 2px 1px rgba(77, 144, 254, 70%);
  }

  &:hover {
    cursor: pointer;
  }

  &_open {
    box-shadow: 0 0 2px 1px rgba(77, 144, 254, 70%);
  }

  &__heading {
  }

  &__popup {
    position: absolute;
    top: 37px;
    left: 0;
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    border-radius: 4px;
    gap: 10px;
    background-color: var(--background-color-default);
    box-shadow: var(--box-shadow-popup);
    z-index: 1;
  }
}
