.segments-units-count-slider {
  position: relative;
  display: flex;
  width: 100%;
  height: 69px;
  align-items: center;

  &__label {
    position: absolute;
    width: auto;
    top: 3.5px;

    &-font {
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      color: #747474;
    }
  }

  .rc-slider {
    &-dot {
      display: none;
    }

    &-handle {
      margin-top: -8.5px;
    }

    &-mark {
      &-text {
        top: -42.1px;
      }
    }

    > .rc-slider-handle-1,
    > .rc-slider-handle-4,
    > .rc-slider-track-2,
    > .rc-slider-track-3 {
      pointer-events: none;
      opacity: 0;
    }
  }
}
