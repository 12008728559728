.route-container {
  position: absolute;
  top: 16px;
  width: 325px;
  background-color: var(--background-color-default);
  left: 16px;
  box-shadow: var(--box-shadow-popup);
  border-radius: 3px;
  bottom: 16px;

  &-error-message {
    color: var(--text-color-error);
  }

  &-error-button {
    border: none;
    background: transparent;
    width: 290px;
    cursor: pointer;
  }

  &-time {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    position: relative;
  }

  &-loader {
    &-wrapper {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }

  &-scroll {
    display: flex;
    flex-direction: column-reverse;
    max-height: calc(100vh - 75px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__tooltip {
    position: absolute;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #ffffff, $alpha: 0.85);
    z-index: 100;
    --app-tooltip-wrapper-width: 305px;
    --app-tooltip-wrapper-height: 100px;
  }
}

@media (max-width: 1300px) {
  .route-container {
    top: 72px;

    &-scroll {
      max-height: calc(100vh - 130px);
    }
  }
}
