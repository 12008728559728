.zone-statistics-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 16px;
  left: 16px;
  width: 380px;
  background-color: var(--background-color-default);
  box-shadow: var(--box-shadow-popup);
  border-radius: 3px;
  z-index: 500;
  user-select: text;

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__divider {
      display: block;
      width: 303px;
      height: 1px;
      background-color: #e0e0e0;
      border: none;
      border-radius: 50%;
    }
  }

  &__loader {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
  }

  .rpc {
    width: 100%;
    &__label {
      width: 100%;

      &__toggle {
        width: 100%;

        &-hyphen {
          background-color: var(--text-color-default);
        }

        &-btn {
          display: flex;
          justify-content: center;
          width: 100%;
          padding-left: 4px;
          color: var(--text-color-default);
        }
      }
    }

    &__area {
      z-index: 1;
    }
  }
}
