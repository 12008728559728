.route-time-tooltip {
  position: absolute;
  padding: 10px;
  background-color: var(--background-color-default);
  box-shadow: var(--box-shadow-popup);
  top: -40px;
  left: 65px;
  font-size: 12px;
  // z-index: 1;

  & span::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid var(--background-color-default);
    left: 46%;
    top: 34px;
    transform: rotate(270deg);
  }
}
