.route-transport-count-popup {
  font-weight: 500;
  min-width: 225px;
  font-size: 12px;

  &__title {
    margin-bottom: 8px;
  }

  &__row {
    display: flex;
    justify-content: space-between;

    & > * {
      line-height: 24px;
    }
  }

  &__label {
    font-weight: 400;
    color: var(--text-color-second);
  }
}
