.route-time-variants-tabs {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;

  &__btn {
    font-size: 12px;
    color: var(--text-color-default);
    font-weight: 500;
    background-color: transparent;
    border: none;

    &_apply {
      color: var(--green-color);
    }

    &_disabled {
      color: #e5e5e5;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-separator {
    color: var(--separator-color-default);
  }
}
