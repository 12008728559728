.graph-legend {
  display: flex;
  gap: 8px;
  padding-left: 22px;
  padding-top: 2px;
}

.button-all-graph-legend-checkboxes {
  cursor: pointer;
  border: none;
  border-radius: 6px;
  width: 59px;
  height: 24px;
  color: #696969;
  font-size: 12px;

  &:hover {
    color: #000000;
  }
}
