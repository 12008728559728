.zone-statistics-card-statistics {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 15px;

  &-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
  }
}
