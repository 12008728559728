.sector-analysis-main .sector-analysis-compare {
  position: absolute;
  left: 0;
  top: 20px;
  transform: translateX(-50%);
  width: 275px;

  .sector-analysis {
    &-card {
      &-header > svg {
        fill: transparent;
        stroke: var(--icon-color-default);
      }

      &-name {
        width: 150px;
      }
    }
  }
}
