.transport-hubs-popup {
  &__content {
    display: flex;
    gap: 5px;
    flex-direction: column;

    &__header {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__img {
      width: 24px;
      height: 24px;
    }

    &__title {
      font-size: 13px;
      line-height: 17px;
      font-weight: 500;
      margin-right: 10px;
    }

    &__value {
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
    }
  }

  .mapboxgl-popup-content {
    background-color: var(--background-color-default);
    color: var(--text-color-main);
    box-shadow: var(--box-shadow-popup);
    padding: 10px 10px;
    box-sizing: border-box;
  }
}
