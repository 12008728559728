.ctrl-traffic {
  width: 70px;
  height: 40px;
  padding: 7px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &-status {
    width: 19px;
    height: 20px;
    display: flex;
    margin-left: 10px;
  }

  &-btn {
    display: block;
    width: 27px;
    height: 26px;
    overflow: hidden;
    background-color: transparent;
    border: none;
    padding: 0;
    border-radius: 21px;

    &:hover {
      cursor: pointer;
    }

    &:not(.apply) {
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
    }
  }

  &-btn,
  &-img {
    display: block;
  }

  .loading {
    position: absolute;

    &::before {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-popup {
    & .mapboxgl-popup-tip {
      border-top-color: var(--background-color-default);
    }

    & strong {
      font-weight: bold;
    }

    &-row {
      display: flex;
      justify-content: space-between;
    }

    &-name {
      padding-right: 10px;
    }

    &-value {
      width: 60px;
    }

    & .mapboxgl-popup-content {
      background-color: var(--background-color-default);
      color: var(--text-color-main);
      box-shadow: var(--box-shadow-popup);
      padding: 10px 10px;
    }
  }
}
