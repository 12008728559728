.filter-external-system {
  &-content {
    position: relative;
    width: 325px;
    height: 40px;
    background-color: var(--background-color-default);
    border-radius: 25px;

    &:hover {
      cursor: pointer;
    }

    &_disabled {
      opacity: 0.5;

      & > * :hover {
        cursor: default;
      }
    }
  }

  &-popup {
    transition: height 0.3s 0s linear;
    background-color: var(--background-color-default);
    width: 100%;
    z-index: var(--control-button-z-index);
    box-shadow: var(--box-shadow-popup);
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    height: 0;
    border-radius: 25px;

    &-visible {
      height: 380px;
      border-top: 1px solid var(--separator-color-default);
    }
  }

  &-header-placeholder {
    height: 40px;
  }
}
