.details-administrative-division__contact-info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &__title {
    font-weight: bolder;
  }

  &__contact {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px solid var(--border-color-default);

    &:first-child {
      margin-top: 8px;
      color: var(--text-color-default);
      border-top: none;
    }
  }

  &__full-name {
    width: 186px;
  }

  &__post {
    width: 288px;
  }

  &__phone-number {
    width: 122px;
  }
}
