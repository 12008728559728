.tooltip-value {
  position: absolute;
  top: -40px;
  left: 0;
  width: 50px;
  background-color: #ffffff;
  text-align: center;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent #ffffff;
    position: absolute;
    top: 100%;
  }

  &[data-align="min"] {
    &:after {
      left: 100%;
      transform: rotate(45deg) translate(-75%, 0);
    }
  }

  &[data-align="max"] {
    &:after {
      left: 0;
      transform: rotate(135deg) translate(0, 75%);
    }
  }
}
