@import "src/styles/mixin";

@mixin marker {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--orange-color);
}

.version-picker {
  position: relative;
  flex-grow: 1;

  & * {
    font-family: "Roboto", sans-serif;
  }

  &__input {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: white;
    padding: 8px 8px 8px 10px;
    font-size: 12px;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid var(--border-color-default);
    border-radius: 4px;

    input {
      border: none;
      padding: 0;
      margin: 0;
      width: calc(100% - 16px);
      pointer-events: none;
      font-weight: 500;
    }

    svg {
      width: 10px;
      height: 10px;
      transform: rotate(90deg);
    }
  }

  &__dropdown {
    @include base-modal-styles;
    display: flex;

    position: absolute;
    transform: translateY(-100%);
    top: -5px;
    left: 0;
  }

  &__calendar {
    width: 260px;

    .react-datepicker-popper {
      transform: none !important;
    }

    &__input {
      display: none;
    }

    &__calendar {
      margin-top: 0 !important;
      box-shadow: none !important;
      border-radius: 0 !important;

      .react-datepicker__day.react-datepicker__day {
        &--today {
          color: #62c400;
        }

        &--selected {
          background-color: transparent;
        }

        &--disabled {
          pointer-events: none;
        }
      }
    }

    &__day--selected {
      position: relative;

      &::after {
        @include marker;

        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -100%);
      }
    }
  }

  &__list {
    width: 298px;
    max-height: 308px;
    overflow: auto;
    list-style: none;

    &__month {
      margin-top: 4px;
      padding: 8px 8px 8px 10px;
      color: var(--text-color-second);
      text-align: left;
      text-transform: capitalize;
    }

    &__item {
      transition: background-color 0.1s ease-in-out;
      cursor: pointer;

      button {
        width: 100%;
        padding: 8px 8px 8px 10px;
        text-align: left;
        display: block;
        background: none;
        border: none;
        font-weight: 500;
      }

      &.selected {
        position: relative;

        &::after {
          @include marker;

          position: absolute;
          content: "";
          right: 0;
          top: 50%;

          transform: translate(-20px, -50%);
        }
      }

      &:focus-within,
      &:hover {
        background-color: var(--background-color-hover);
      }
    }
  }
}
