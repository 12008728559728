.ctrl-route {
  &-variants {
    position: relative;

    &-back {
      padding: 5px 15px;

      &-button {
        display: block;
        width: 100%;
        background-color: #fff;
        border: none;
        text-align: right;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }

      &-text {
        opacity: 0.54;
        font-size: 15px;
      }
    }

    &-error {
      padding: 16px;
      color: red;
    }

    &-container {
      position: relative;
      min-height: 40px;
    }
  }
}
