.filter-system {
  &-popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: height 0.3s 0.2s linear;
    background-color: var(--background-color-default);
    width: 350px;
    z-index: var(--control-button-z-index);
    box-shadow: var(--box-shadow-popup);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    height: 0;

    &-visible {
      height: 380px;
      border-top: 1px solid var(--separator-color-default);
    }
  }

  &-header-placeholder {
    height: 40px;
  }
}
