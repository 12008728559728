.route-favorite-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  position: relative;

  &:hover {
    background-color: var(--background-color-hover);
    cursor: pointer;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
  }

  &-left {
    display: flex;
  }

  &-star {
    position: relative;
    font-size: 26px;
    left: -2px;
  }

  &-edit {
    font-size: 20px;
    position: relative;
    left: 7px;
  }

  &-label {
    padding-left: 10px;
    color: var(--text-color-default);
    line-height: 35px;
    max-width: 235px;
  }

  &-name {
    color: var(--text-color-main);
    font-size: 14px;
    max-width: 225px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-address {
    font-size: 12px;
    max-width: 225px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
