.sector-analysis {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 72px;
  left: 357px;
  max-width: 50%;
  max-height: 881px;
  bottom: 16px;
  background-color: var(--background-color-default);
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  transition: width linear 0.1s;
  z-index: var(--control-popup-z-index);

  &-legend {
    z-index: 1001;
    margin-left: 86px;
    margin-top: -11px;
  }

  &-settings-button {
    position: absolute;
    top: 4px;
    left: calc(100% - 84px);
    z-index: 1001;
  }

  &-appearance-tooltip {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 650px;
    top: 100%;
    right: 50%;
    transform: translate(0, 7px);
    z-index: 1001;
  }

  &-loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--background-color-loader);
    z-index: 999999;
  }

  &-mode-compare {
    max-width: 70%;

    .sector-analysis-appearance-tooltip {
      right: 780px;
    }

    .sector-analysis-legend {
      right: 817px;
    }

    @media screen and (min-width: 1400px) {
      max-width: 73%;
    }

    @media screen and (min-width: 1520px) {
      max-width: 75%;
    }

    @media screen and (min-width: 1800px) {
      max-width: 77%;
    }

    @media screen and (min-width: 1920px) {
      max-width: 100%;
    }
  }

  &-main {
    width: 100%;
    position: relative;
    display: flex;
    max-width: 1536px;
    height: calc(100% - 40px);
    overflow: scroll;
  }

  &-diagram {
    max-width: 768px;

    &__graph {
      position: relative;
      width: 768px;
      height: 115px;
      canvas {
        width: 100%;
        height: 100%;
      }
    }

    &__diagram {
      position: relative;
      width: 768px;
      flex: 1;
      height: 524px;

      &_3d {
        display: flex;
        width: 100%;
        height: 524px;
        background-color: #ffffff;

        &_fullscreen {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          z-index: 999999;
          background-color: #ffffff;
        }
      }

      canvas {
        width: 100%;
        height: 100%;
      }
    }

    &__fullscreen {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 32px;
      right: 32px;
      opacity: 0.3;
      z-index: 999999;

      &:hover {
        opacity: 0.5;
      }
    }
  }
  &-content {
    position: relative;
    width: 768px;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  &-info {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    padding: 20px 50px 20px 30px;
    gap: 20px;

    & > * {
      flex-basis: 216px;
    }

    &__separator {
      align-self: stretch;
      width: 1px;
      background-color: var(--separator-color-default);
    }

    &.compare-mode {
      & > * {
        flex-basis: 180px;
      }
    }

    &.compare {
      justify-content: flex-end;
      padding: 20px 30px 20px 140px;
    }
  }

  &-placeholder {
    padding: 16px;
    font-weight: 500;
    width: 100%;
    height: 100%;
  }

  &-tooltip-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
}

.analysis-tooltip {
  position: absolute;
  z-index: var(--control-popup-z-index);
  transition: all 0.05s linear;
}
