.button-group {
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
  border-radius: 25px;
  box-shadow: var(--box-shadow-button);
  background-color: var(--background-color-default);
  overflow: hidden;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  transition: height 0.3s;
  z-index: 100;
  &_open {
    height: 85px;
  }

  &__items {
    display: flex;
    align-items: center;
    height: 40px;
  }

  &__separator {
    border-right: 1px solid black;
    height: 20px;
    margin: 4px;
    border-color: var(--border-color-default);
  }

  &__popup {
    display: flex;
    flex-grow: 1;
    padding-top: 10px;
    padding-left: 10px;
  }
}
