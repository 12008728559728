$main-class: "ctrl-weather";

.cwp-popup-main {
  &.mapboxgl-popup-anchor- {
    &bottom {
      top: -20px !important;
    }

    &top {
      top: 20px !important;
    }

    &right {
      left: -20px !important;
    }

    &left {
      left: 20px !important;
    }
  }
}

.ctrl-weather {
  $active-color: #62c400;
  $primitive-color: #fff;
  $deactivate-color: #d1d1d1;
  $dark-color: rgba(0, 0, 0, 0.87);

  &-button {
    cursor: pointer;
    padding: 8px;
    outline: none;
    border-radius: 5px;
    border: none;
    background: var(--background-color-default);
    box-shadow: var(--box-shadow-popup);

    &.active {
      background: var(--background-color-active);

      .ctrl-weather-button__icon {
        fill: var(--icon-color-active);

        & .cls-3 {
          stroke: var(--icon-color-active);
        }
      }

      &:hover {
        .ctrl-weather-button__icon {
          fill: var(--icon-color-default);

          & .cls-3 {
            stroke: var(--icon-color-default);
          }
        }
      }
    }

    &.deactivate {
      background: $deactivate-color !important;
      cursor: default !important;

      &:hover {
        .ctrl-weather-button__icon {
          fill: $dark-color !important;

          & .cls-3 {
            stroke: $dark-color !important;
          }
        }
      }
    }

    &:hover {
      .ctrl-weather-button__icon {
        fill: $active-color;

        & .cls-3 {
          stroke: $active-color;
        }
      }
    }

    &__icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      fill: $dark-color;

      & .cls-3 {
        stroke: $dark-color;
      }
    }
  }

  &-popup {
    width: 542px;

    .cwp-select {
      &__control {
        width: 150px;
        margin-left: 13px;
      }
    }

    .cwp-header {
      height: 44px;
      display: flex;
      flex-direction: row;

      &-image {
        position: relative;
      }

      &-name {
        width: 222px;
        display: flex;
        flex-direction: row;

        &-text {
          line-height: 44px;
          padding-left: 14px;
          font-weight: bold;
        }
      }

      &-data {
        display: flex;
        flex-direction: row;
        flex-grow: 999;

        & > div {
          width: 88px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 56px;
          letter-spacing: normal;
          text-align: center;
          color: #000;
        }
      }
    }

    .cwp-data-show {
      height: 40px;

      &-text {
        margin-top: 13px;
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: rgba(0, 0, 0, 0.54);
        padding-left: 10px;
        cursor: pointer;
      }
    }

    .cwp-data-from {
      height: 56px;
      border-top: 1px solid #d1d1d1;
      display: flex;
      flex-direction: row;

      &-header {
        width: 222px;
        display: flex;
        flex-direction: row;

        &-text {
          width: 120px;
          text-align: left;
          padding-left: 13px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 56px;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.54);
        }

        img {
          display: inline-block;
          padding: 10px;
        }
      }

      &-data {
        display: flex;
        flex-direction: row;
        flex-grow: 999;

        & > div {
          flex-grow: 1;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 56px;
          letter-spacing: normal;
          text-align: center;
          color: #000;
        }
      }
    }

    .cwp-data-fact {
      height: 56px;
      border-top: 1px solid #d1d1d1;
      // border-bottom: 1px solid #d1d1d1;
      display: flex;
      flex-direction: row;

      &-header {
        width: 222px;
        display: flex;
        flex-direction: row;

        &-text {
          width: 120px;
          text-align: left;
          padding-left: 13px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 56px;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.54);
        }

        img {
          display: inline-block;
          padding: 10px;
        }
      }

      &-data {
        display: flex;
        flex-direction: row;
        flex-grow: 999;

        & > div {
          flex-grow: 1;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 56px;
          letter-spacing: normal;
          text-align: center;
          color: #000;
        }
      }
    }
  }
}

.rc-tooltip {
  opacity: 1;
  display: none;

  &.rc-tooltip-placement-top {
    display: block;

    .rc-tooltip-arrow {
      border-top-color: white;
    }

    .rc-tooltip-inner {
      background-color: white;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
      border-radius: 3px;

      &-text {
        color: var(--text-color-default);
        font-size: 12px;
        padding-top: 2px;
      }
    }
  }

  &.rc-tooltip-hidden {
    display: none;
  }
}

.svg-wind-1 {
  opacity: 0.54;
}

.svg-wind-2,
.svg-wind-3 {
  fill: none;
}

.svg-wind-3 {
  stroke: #000;
  stroke-linecap: round;
  stroke-width: 1.4px;
  fill-rule: evenodd;
}
