@mixin abs-clearfix() {
  &::before,
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin add-icon($name, $value: "") {
  .dtm-#{$name}-icon {
    &::before {
      content: $value;
    }
  }
}

@mixin base-modal-styles {
  background-color: var(--background-color-default);
  box-shadow: var(--box-shadow-popup);
  border-radius: 3px;
}
