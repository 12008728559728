.mapboxgl-popup-close-button {
  font-size: 20px;
  padding: 4px 6px;
  background: #fafafa;
  border-radius: 4px;
  line-height: 1;

  &:hover {
    cursor: pointer;
  }
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}
