.new-react-map-popup {
  width: 320px;
  position: relative;
  background-color: var(--background-color-default);
  padding: 10px;
  box-shadow: var(--box-shadow-popup);
  border-radius: var(--border-radius-popup);

  &__title {
    color: var(--link-color-default);
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 265px;
    display: block;
    width: fit-content;
  }

  &__address {
    color: var(--text-color-main);
    font-size: 13px;
    padding: 5px 0;
  }

  &__drag {
    width: 12px;
    height: 12px;
    fill: var(--icon-color-default);
    cursor: grab;
    margin-right: 6px;

    &:hover {
      fill: var(--icon-color-hover);
    }
  }

  &__buttons {
    position: absolute;
    top: 10px;
    background-color: var(--background-color-default);
    right: 10px;
  }
}
