.close-button {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
    fill: var(--icon-color-default);
  }

  &:hover {
    cursor: pointer;

    svg {
      fill: var(--icon-color-hover);
    }
  }
}
