.application-settings-popup {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  width: 360px;
  padding: 0;
  top: 45px;
  right: 0;
  height: 0;
  opacity: 0;
  transition: height 0.1s linear;
  background-color: var(--background-color-default);
  box-shadow: var(--box-shadow-popup);
  border-radius: 3px;
  overflow: hidden;

  &_visible {
    opacity: 1;
    padding: 18px;
    height: auto;
  }

  &:empty {
    display: none;

    ~ * {
      display: none;
    }
  }
}
