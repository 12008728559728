.radio-button {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0;
  margin: 0;
  user-select: none;
  border: none;
  background-color: var(--background-color-default);
  padding: 0;
  margin: 0;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  &[aria-disabled="true"] {
    cursor: default;
  }

  &__caption {
    margin-left: 17px;
    color: var(--text-color-default);
    font-size: 14px;
  }

  &__icon-conatainer {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;

    svg {
      fill: var(--border-color-main);
    }

    &_checked > svg {
      fill: var(--background-color-active);
    }
  }

  &__input {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
    margin-left: 5px;
  }
}
