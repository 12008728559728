.sector-analysis-units-count-legend {
  display: flex;
  width: 100%;
  gap: 36px;

  &__circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #e5e5e5;
    margin-right: 8px;
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__units {
    font-size: 10px;
    line-height: 12px;
    color: #747474;
  }
}
