.sector-analysis-tooltip-incident {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &__list {
    margin-top: 16px;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
    color: var(--green-color);
    text-decoration: none;
  }

  &__description {
    margin: 5px;
    font-size: 12px;
    color: var(--text-color-main);
  }
}
