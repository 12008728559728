.app-controls-wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  top: 16px;

  &__side {
    display: flex;

    &_group {
      justify-content: flex-end;
    }

    &_points {
      justify-content: flex-end;
      padding-right: 200px;
      flex-grow: 1;
    }
  }

  &-group {
    &__roads {
      display: flex;

      &__ctrl {
        padding-left: 18px;
        padding-right: 18px;
      }
    }

    &__filter-roads {
      padding-right: 18px;
    }

    &__route {
      display: flex;
      width: 455px;
      justify-content: flex-end;
      margin: 0 23px 0 18px;
    }
  }
}
