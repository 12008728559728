@import "./variables.scss";
@import "./_mixin.scss";
@import "./app.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://api.tiles.mapbox.com/mapbox-gl-js/v0.52.0/mapbox-gl.css");
@import "./mapbox-gl.scss";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: normal normal 14px/1.29 "Roboto";
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

html {
  font-size: 10px;
}

body {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

html,
body {
  position: relative;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
// end font

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

del {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto;
}

a img {
  border: none;
}

blockquote,
q {
  quotes: none;

  &::before,
  &::after {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.nobr {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

button {
  padding: 0;
}
