.external-system-header {
  display: flex;
  color: var(--text-color-default);
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  background-color: var(--background-color-default);
  border-radius: 25px;
}
