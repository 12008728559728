.ctrl-points {
  font-size: 20px;
}

.telemetry-map-close-icon {
  background-color: var(--background-color-default);
  border: none;
  border-radius: 50%;
  padding: 5px;
  box-shadow: var(--box-shadow-button);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--icon-color-default);
}
