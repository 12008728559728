.dtm-popup__bus-stops {
  max-width: 450px !important;

  .mapboxgl-popup-content {
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    border-radius: 4px;
    padding: 10px;
  }

  &__content {
    display: flex;
    width: 450px;
    flex-direction: column;

    &__meta {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__title {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      opacity: 0.87;

      &_margin-bottom {
        margin-bottom: 12px;
      }
    }

    &__routes {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin-top: 8px;

      &__route {
        display: flex;
        gap: 8px;
        align-items: center;
        height: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        img {
          width: 24px;
          height: 24px;
        }

        &__num {
          padding: 4px;
          border-radius: 4px;
          font-weight: 500;
          font-size: 12px;
          color: #ffffff;
        }
      }
    }
  }
}
