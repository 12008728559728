.route-variants-item {
  display: flex;
  flex-direction: column;
  padding: 14px;
  border-left: 4px solid transparent;

  &:hover {
    cursor: pointer;
  }

  &-left {
    & > i {
      font-size: 24px;
      color: var(--text-color-default);
    }
  }

  &-difference {
    font-weight: 500;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
  }

  &-distance {
    font-weight: 500;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
  }

  &-maneuvers {
    border: none;
    background-color: transparent;
    color: var(--green-color);
    font-size: 14px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }

  &-destination-time {
    font-weight: inherit;
  }

  &-route-idz {
    margin-left: 4px;
    > svg {
      width: 20px;
      height: 20px;
    }
  }

  &-route-time {
    padding-left: 8px;
    font-weight: 500;
  }

  &-center {
    display: flex;
    align-items: center;

    .ctrl-traffic-btn {
      width: 20px;
      height: 20px;
    }
  }

  &-right {
    font-weight: 500;
  }

  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: 20px;
  }

  &-active {
    background-color: var(--background-color-hover);
    border-left-color: var(--green-color);
  }

  &-score {
    &-0,
    &-1,
    &-2 {
      color: var(--score-1);
    }

    &-3 {
      color: var(--score-3);
    }

    &-4,
    &-5 {
      color: var(--score-4);
    }

    &-6 {
      color: var(--score-6);
    }

    &-7,
    &-8 {
      color: var(--score-7);
    }

    &-9,
    &-10 {
      color: var(--score-9);
    }
  }
}
