.ctrl-meteostation {
  .map-ms-ctrl {
    &__change {
      width: 40px;
      height: 40px;
      border: none;
      background-color: var(--background-color-default);
      border-radius: 0;

      &.apply-state {
        background-color: var(--background-color-default);
        border: none;

        svg {
          path {
            fill: var(--background-color-active);
          }
        }

        &:hover {
          svg {
            path {
              fill: var(--background-color-active);
            }
          }
        }
      }

      &:hover {
        svg {
          path {
            fill: var(--icon-color-hover);
          }
        }
      }
    }

    &_change {
      &-icon {
        position: relative;
        top: 2px;
        width: 23px;
        height: 23px;
        fill: var(--icon-color-default);
      }
    }
  }
}
