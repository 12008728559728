.ctrl-blocking {
  font-size: 20px;
}

.blocking-name {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }

  span {
    font-weight: 600;
  }
}

.blocking-small {
  font-size: 12px;
}
