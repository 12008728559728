.equipments-group {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50% - 280);
  transition: all 0.3s linear;
  div > img {
    width: 20px;
  }
}
