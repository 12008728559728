.graph-settings {
  &__heading {
    font-weight: bold;
    font-size: 14px;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding-top: 19px;
  }

  &__checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  hr {
    padding: 0;
    margin: 0;
    height: 1px;
    border: none;
    color: #e0e0e0;
    background-color: #e0e0e0;
  }
}
