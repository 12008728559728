.route-time-picker {
  position: relative;
  height: 32px;

  &-buttons {
    position: absolute;
    top: -1px;
    right: 5px;
    display: flex;
    height: 100%;
    align-items: center;
    width: 45px;
    align-items: center;
    justify-content: space-around;
  }

  &-button {
    background-color: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }

    & > i {
      font-size: 7px;
      color: var(--text-color-default);
    }

    &-right {
      transform: rotate(270deg);
    }

    &-left {
      transform: rotate(90deg);
    }
  }
}
