.header-block {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  align-items: center;
  border-bottom: 1px var(--separator-color-default) solid;

  &__title {
    font-weight: 600;
    font-size: 14px;
    color: var(--text-color-default);
  }
}

.buttons-block {
  display: flex;
  gap: 17px;
  align-items: center;

  &__hidden {
    cursor: pointer;
    transition: transform 0.2s;
    transform: rotate(0deg);
  }
  &__show {
    cursor: pointer;
    transition: transform 0.2s;
    transform: rotate(-90deg);
  }
}
