.external-system-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__type {
    display: block;
    width: 28px;
    height: 28px;

    &_disabled {
      opacity: 0.5;
    }
  }

  svg {
    fill: var(--icon-color-disabled);
    width: 28px;
    height: 28px;
  }

  .car-icon {
    stroke: var(--icon-color-disabled);
    stroke-width: 0.5;
  }

  &_active {
    svg {
      fill: var(--icon-color-default);
    }

    .car-icon {
      stroke: var(--icon-color-default);
    }
  }
}
