.travel-heatmap-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: auto;
  top: 16px;
  left: 16px;
  width: 400px;
  max-height: calc(100% - 32px);
  background-color: var(--background-color-default);
  box-shadow: var(--box-shadow-popup);
  border-radius: 3px;
  z-index: 999999;
  user-select: text;

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 10px 16px 11px;
    border-bottom: 1px solid #e0e0e0;

    &__heading {
      display: flex;
      flex-grow: 1;
      align-items: center;
      font-size: 14px;
      color: var(--text-color-default);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 16px;
    gap: 10px;

    &__heading {
      font-weight: 600;
    }

    &__period {
      margin-left: 10px;
      color: var(--text-color-default);

      &__date {
        color: #000000;
        font-weight: 600;
      }
    }

    &__count {
      color: var(--text-color-default);
      font-size: 32px;
      font-weight: bolder;
    }

    &__accordion {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5f5f5;
      color: #444;
      cursor: pointer;
      width: 100%;
      height: 32px;
      text-align: left;
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 10px;
      color: var(--text-color-default);

      &__indicator {
        height: auto;
        font-size: 16px;
        line-height: 16px;
      }
    }

    &__panel {
      background-color: white;
      display: flex;
      flex-direction: column;
      gap: 2px;
      height: 0;
      overflow: auto;
      padding: 0;
      transition: height 0.3s;

      &-open {
        height: 164px;
        padding: 2px 0;
      }

      &__sector {
        width: 96%;
        margin: 0 2px;
        padding: 2px 10px;
        user-select: all;
        word-wrap: normal;

        &-selected {
          border-radius: 4px;
          outline: 2px solid #4caf50;
          cursor: default;
        }
      }
    }

    canvas {
      width: 100%;
      height: 200px;
    }
  }

  &__separator {
    display: block;
    width: 100%;
    max-height: 1px;
    min-height: 1px;
    background-color: #e0e0e0;
    margin: 15px 0;
  }

  &__graphic-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 18px;

    &__heading {
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
  }

  &__loader {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
  }
}
