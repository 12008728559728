.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 15px;
  background: #f0f0f0;

  &_title {
    font-weight: bold;
  }
  &_diffText {
    cursor: pointer;
    color: var(--text-color-default);
    font-size: 12px;
  }
  &_diffText:hover {
    color: var(--text-color-hover);
  }
}

.block {
  display: flex;
  justify-content: space-between;

  &_caption {
    font-weight: 400;
    font-size: 12px;
    color: var(--text-color-default);
  }

  &_arrivalTime {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }

  &_time-label {
    &_0,
    &_1,
    &_2 {
      color: var(--score-1);
    }

    &_3 {
      color: var(--score-3);
    }

    &_4,
    &_5 {
      color: var(--score-4);
    }

    &_6 {
      color: var(--score-6);
    }

    &_7,
    &_8 {
      color: var(--score-7);
    }

    &_9,
    &_10 {
      color: var(--score-9);
    }
  }
}
