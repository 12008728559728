:root {
  --text-color-default: #747474;
  --text-color-hover: #202020;
  --text-color-main: #212121;
  --text-color-second: #a8a8a8;
  --text-color-disabled: #dfdfdf;
  --text-color-active: white;
  --text-color-error: red;
  --icon-color-default: #747474;
  --icon-color-hover: #202020;
  --icon-color-disabled: #dfdfdf;
  --icon-color-active: white;
  --border-color-default: #e2e2e2;
  --border-color-active: #62c400;
  --border-color-main: #747474;
  --border-color-focus: #747474;
  --border-color-hover: #b2b2b2;
  --border-color-error: red;
  --background-color-default: white;
  --background-color-loader: rgba(255, 255, 255, 0.7);
  --background-color-active: #62c400;
  --background-color-hover: #f5f5f5;
  --background-color-second: #f0f0f0;
  --separator-color-default: #e0e0e0;
  --box-shadow-popup: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --box-shadow-button: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  --link-color-default: #62a60a;
  --green-color: #62c400;
  --orange-color: #fda600;
  --green-main: #7cbf3a;
  --score-1: #71b732;
  --score-3: #59b200;
  --score-4: #fc0;
  --score-6: #fda600;
  --score-7: #f33;
  --score-9: #e00000;
  --border-radius-popup: 3px;
  --background-z-index: 1;
  --map-popup-z-index: 10;
  --popup-z-index: 100;
  --control-button-z-index: 500;
  --control-popup-z-index: 1000;
}
