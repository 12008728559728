.single-button {
  width: 40px;
  height: 40px;
  border: none;
  box-shadow: var(--box-shadow-button);
  background-color: var(--background-color-default);
  color: var(--text-color-default);
  font-size: inherit;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    fill: var(--icon-color-default);
  }

  &:hover {
    color: var(--text-color-hover);
    cursor: pointer;

    & svg {
      fill: var(--icon-color-hover);
    }
  }

  &-disabled {
    & svg {
      fill: var(--icon-color-disabled);
    }

    color: var(--text-color-disabled);

    &:hover {
      & svg {
        fill: var(--icon-color-disabled);
      }

      color: var(--text-color-disabled);
    }

    &:hover {
      cursor: default;
    }
  }

  &-active {
    background-color: var(--background-color-active);
    color: var(--text-color-active);

    & svg {
      fill: var(--icon-color-active);
    }

    &:hover {
      color: var(--text-color-active);

      & svg {
        fill: var(--icon-color-active);
      }
    }
  }

  &-label {
    font-size: 24px;
    font-weight: bold;
  }

  &-icon {
    color: inherit;
    font-size: inherit;
  }

  &-square {
    border-radius: 5px;
    font-size: inherit;
  }

  &-round {
    border-radius: 25px;
    font-size: inherit;
  }

  &-group-item {
    border: none;
    box-shadow: none;
    border-radius: 0;

    &.single-button-active {
      background-color: var(--background-color-default);
      color: var(--background-color-active);

      & svg {
        fill: var(--background-color-active);
      }
    }
  }
}
