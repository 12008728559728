.application-settings {
  position: absolute;
  font-size: 18px;
  top: 0;
  right: 77px;
}

.trait {
  border: 1px solid #e0e0e0;
}

.reduce-margin {
  margin-bottom: -24px;
}
