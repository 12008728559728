.favorite-button {
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
  fill: transparent;
  stroke: var(--icon-color-default);
  cursor: pointer;

  &_active {
    fill: var(--icon-color-default);
    stroke: var(--icon-color-default);

    &:hover {
      fill: var(--icon-color-hover);
      stroke: var(--icon-color-hover);
    }
  }

  &:hover {
    stroke: var(--icon-color-hover);
  }
}
