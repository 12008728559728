.dtm-popup {
  z-index: 1;

  & strong {
    font-weight: 500;
  }

  &-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-name {
    padding-right: 10px;
  }

  &-value {
    width: 60px;
  }

  & .mapboxgl-popup-content {
    background-color: var(--background-color-default);
    color: var(--text-color-main);
    box-shadow: var(--box-shadow-popup);
    padding: 10px 10px;
  }
}
