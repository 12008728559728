.route-isochrone-ctrl {
  padding-top: 16px;
  display: flex;
  font-size: 12px;

  &-loader {
    width: 14px;
    height: 14px;
    background-size: 14px;
    position: relative;
  }
}
