.route-favorite-edit {
  background-color: transparent;
  border: none;
  font-size: inherit;

  &:hover {
    cursor: pointer;
  }

  & > i {
    font-size: inherit;
    color: var(--text-color-default);
  }
}
