.dtm-select {
  &__value-container {
    padding-left: 5px !important;
    padding-left: 5px !important;

    :hover {
      background-color: var(--border-color-default);
    }
  }

  &__control {
    border: 0.5px solid var(--border-color-default) !important;
    &:hover {
      cursor: pointer;
    }
  }

  &-indicator {
    & > i {
      font-size: 8px;
    }
  }

  &__dropdown-indicator {
    padding-right: 10px !important;
  }
}
