.route-select-template {
  padding: 0 15px;
  position: relative;
  transform: translate(0, 0);

  .rst {
    &__select {
      --megapolis-select__head__label__border__color: var(--border-color-default);
      --megapolis-select__head__label__border__color_hover: var(--border-color-hover);
      --megapolis-select__head__button__border-radius: 3px;
    }
  }
}
