.instruments {
  text-align: right;
  height: 40px;

  &-distance {
    position: absolute;
    top: 100px;
    right: 100px;
    text-align: right;
    height: 40px;
    width: 200px;
  }

  &-measure-distance {
    position: absolute;
    top: 75px;
    right: 15px;
    z-index: var(--control-popup-z-index);
    background-color: var(--background-color-default);
    color: var(--text-color-default);
    box-shadow: var(--box-shadow-popup);
    font-size: 11px;
    line-height: 18px;
    display: block;
    margin: 0;
    padding: 5px 10px;
    border-radius: 3px;

    &-text {
      display: inline;
    }

    &-clear-button {
      display: inline;
      background-color: transparent;
      border: none;
      color: var(--text-color-default);
      font-weight: bold;
      border-radius: 10px;
      margin-left: 10px;
      cursor: pointer;

      &:hover {
        color: var(--text-color-active);
        background-color: var(--text-color-default);
      }
    }

    &-popup {
      & .mapboxgl-popup-tip {
        border-top-color: white;
      }

      & .mapboxgl-popup-content {
        background-color: var(--background-color-default);
        color: var(--text-color-default);
        box-shadow: var(--box-shadow-popup);
        padding: 5px 5px;
        font-weight: 500;
      }
    }
  }
}
