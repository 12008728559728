.navigation-groupe-layers {
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    padding: 0;
    border: none;
    outline: none;
    background-color: #ffffff;

    svg {
      margin-right: -1.5px;
    }

    img {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
