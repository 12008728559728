.after-trait-text {
  color: #747474;
  margin-bottom: 10px;
}

.after-trait-text-version {
  color: #747474;
  margin-bottom: 6px;
  font-size: 15px;
}

.after-trait-text-hash {
  color: #d7d7d7;
}

.after-trait-text-version-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
