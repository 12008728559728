.sector-analysis-appearance-menu {
  display: flex;
  flex-direction: column;
  width: 642px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
  border-radius: 4px;
  padding: 16px 18px;

  &__label {
    label {
      font-size: 12px;
      margin-right: 5px;
      color: var(--text-color-default);
    }
  }

  &__header {
    margin-bottom: 10px;
  }

  &__view-mods {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
  }

  &__heading {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }

  &__row {
    display: flex;
    flex-grow: 1;
    min-height: 69px;
    justify-content: space-between;
    align-items: center;

    &-thin {
      min-height: 27px;
    }

    &-distanced {
      margin-top: 10px;
    }
  }

  &__legend {
    display: flex;
    align-items: center;

    label {
      font-size: 12px;
      margin-right: 5px;
      color: var(--text-color-default);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__graph-settings {
    margin-top: 16px;

    label {
      font-size: 12px;
      margin-right: 5px;
      color: var(--text-color-default);
    }
  }
}
