.route-variant-bus {
  padding: 8px 16px;
  display: flex;
  background-color: var(--background-color-hover);
  border-left: 4px solid var(--green-color);

  &__icon {
    color: var(--icon-color-default);
    margin-right: 16px;
  }

  &__length {
    display: block;
    font-size: 14px;
    color: var(--text-color-main);
    font-weight: 500;
    line-height: 1.29;
  }

  &__time {
    display: block;
    font-size: 12px;
    color: var(--text-color-main);
    line-height: 1.5;
    margin: 0 12px;
  }

  &__maneuvers {
    border: none;
    background-color: transparent;
    color: var(--green-color);
    font-size: 14px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }

  &__traffic-score {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__row {
    position: relative;
    display: flex;
    gap: 10px;
    padding: 5px 0;

    &_col {
      flex-direction: column;
    }
  }

  &__scores {
    position: relative;
    display: flex;
    min-width: 44px;
    gap: 4px;

    &__score {
      position: relative;
      min-width: 20px;
      min-height: 20px;
    }
  }

  &__transports {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 200px;
    flex-wrap: wrap;
  }

  &__bus-route-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__bus-route {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__route-number {
    display: block;
    height: 22px;
    background-color: #62c400;
    color: #ffffff;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 14px;
  }
}
