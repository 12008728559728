.zone-statistics-card-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px 16px 11px;
  border-bottom: 1px solid #e0e0e0;

  &__heading {
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-size: 14px;
    color: var(--text-color-default);
  }
}
