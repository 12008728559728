.time-slider {
  position: relative;
  display: flex;
  width: 100%;
  height: 28px;
  align-items: flex-end;

  &__label {
    position: absolute;
    top: 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #747474;
  }

  .rc-slider-handle {
    width: 5px;
    border: 1px solid #e2e2e2;
    border-radius: 0;
    outline: none;

    &-click {
      outline: none !important;
      box-shadow: none !important;
      border: 1px solid #e2e2e2 !important;

      &-focused {
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid #e2e2e2 !important;
      }
    }

    &-dragging {
      outline: none !important;
      box-shadow: none !important;
      border: 1px solid #e2e2e2 !important;
    }

    &-focused {
      outline: none !important;
      box-shadow: none !important;
      border: 1px solid #e2e2e2 !important;
    }

    &:hover {
      outline: none;
      border: 1px solid #e2e2e2;
    }

    &:active {
      outline: none;
      border: 1px solid #e2e2e2;
      box-shadow: none;
    }
  }

  .rc-slider {
    &-dot {
      background-color: #ffffff;
      border: none;
      width: 1px;
    }

    &-disabled {
      background-color: unset;
    }

    &-track {
      background-color: #62c400;
    }
  }
}
