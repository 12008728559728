.route-diagram {
  &__button {
    position: absolute;
    top: 80px;
    right: 16px;
    z-index: 999999;
    width: 40px;
    height: 40px;
    border: none;
    outline: none;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    border-radius: 50%;
    color: var(--text-color-default);

    &:hover {
      color: var(--text-color-hover);
      cursor: pointer;
    }

    &_disabled {
      background-color: #e5e5e5;
      color: grey;

      &:hover {
        background-color: #e5e5e5;
        color: grey;
        cursor: default;
      }
    }

    &_active {
      color: var(--background-color-active);

      &:hover {
        color: var(--background-color-active);
        cursor: pointer;
      }
    }
  }
}
