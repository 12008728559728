.legend-item {
  display: flex;
  align-items: center;

  &__title {
    display: block;
    font-size: 10px;
    line-height: 12px;
    color: #747474;
  }
}
