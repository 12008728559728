.route-input-row {
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  position: relative;
  padding-left: 15px;
  // &:first-child {
  //   padding-top: 0;
  // }
  // &:last-child {
  //   padding-bottom: 0;
  // }
  &-favorite {
    font-size: 24px;
    margin-left: 5px;
    position: relative;
    top: 2px;
    left: 5px;
  }

  &-address {
    width: 228px;
    margin-left: 16px;
  }

  &-point {
    width: 20px;
    height: 20px;
  }

  &-drag {
    position: absolute;
    left: 2px;

    & > i {
      font-size: 20px;
    }
  }

  &-loader {
    position: relative;
  }
}
