.route-variant-pedestrian {
  padding: 8px 16px;
  display: flex;
  background-color: var(--background-color-hover);
  border-left: 4px solid var(--green-color);

  &__icon {
    color: var(--icon-color-default);
    margin-right: 16px;
  }

  &__info-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  &__length {
    font-size: 14px;
    color: var(--text-color-main);
    font-weight: 500;
    line-height: 1.29;
  }

  &__time {
    font-size: 12px;
    color: var(--text-color-main);
    line-height: 1.5;
  }

  &__maneuvers {
    border: none;
    background-color: transparent;
    color: var(--green-color);
    font-size: 14px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    gap: 12px;
    padding: 5px 0;

    &__length-time {
      display: flex;
      gap: 12px;
    }
  }
}
