.route-section {
  padding: 10px 15px 15px 15px;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border: none;
  }

  &-no-padding {
    padding: 0;
  }
}
