.route-main {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding: 15px 0;

  &-buttons {
    padding: 15px 15px 0 15px;
    display: flex;
    justify-content: space-between;
  }

  &-button {
    &-exchange {
      background-color: transparent;
      border: none;
      position: absolute;
      top: 44px;
      left: 11px;
      cursor: pointer;
      color: var(--text-color-default);

      &:hover {
        color: var(--text-color-hover);
      }

      & > i {
        font-size: 28px;
        color: inherit;
      }
    }
  }
}
