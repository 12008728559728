.route-length-error-popup {
  .mapboxgl-popup-content {
    display: flex;
    width: auto;
    height: auto;
    padding: 0;
    box-sizing: border-box;
  }

  &-container {
    display: flex;
    flex-direction: column;
    width: 400px;
    border-radius: 4px;
    border: 1px solid #e5e5e5;
    padding: 10px;

    span {
      text-align: center;
      color: var(--text-color-default);

      &:first-child {
        margin-bottom: 5px;
      }

      &:last-child {
        font-size: 13px;
      }
    }

    &__description {
      span {
        font-weight: 600;
      }
    }
  }

  &__count {
    padding: 10px;
  }
}
