.multi-day-selector {
  position: relative;

  &-label {
    width: 105px;
    background-color: #ffffff;
    border: none;
    outline: none;
    font-size: 12px;
    line-height: 1.5;
    color: var(--text-color-default);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    &-text {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &-popup {
    border-radius: 3px;
    box-shadow: var(--box-shadow-popup);
    background-color: var(--background-color-default);
    z-index: var(--popup-z-index);
    overflow: hidden;
    padding: 10px 0 10px 0;
    width: 105px;

    &__buttons {
      display: flex;
      flex-direction: column;

      button {
        text-align: left;
        border: none;
        background-color: #ffffff;
        outline: none;
        color: var(--text-color-default);
        margin-top: 5px;
        font-size: 12px;
        line-height: 1.5;
        font-weight: bold;
        padding: 0 10px;

        &:hover {
          cursor: pointer;
          color: var(--text-color-hover);
        }
      }
    }
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color-default);
    width: 100%;
    padding: 4px 10px;
    color: var(--text-color-default);
    cursor: pointer;
    font-size: 12px;
    line-height: 1.5;

    &:hover {
      color: var(--text-color-hover);
      background-color: var(--background-color-hover);
    }

    & > svg {
      position: relative;
      left: 5px;
    }
  }

  &-name {
    display: block;
    font-size: 12px;
    line-height: 1.5;
  }

  &-checkbox {
    display: grid;
    place-items: center;

    & .route-checkbox-container {
      padding: 0;
      position: relative;
      top: 1px;
      right: 7px;
    }
  }

  &-separator {
    margin-top: 5px;
    border-bottom: 1px solid var(--separator-color-default);
    width: 100%;
    display: block;
  }

  &-disabled {
    filter: opacity(0.5);

    .multi-day-selector {
      &-label {
        cursor: default;
      }
    }
  }
}
