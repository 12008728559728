.travel-heatmap-popup {
  &__content {
    display: flex;
    gap: 5px;
    flex-direction: column;

    span {
      word-spacing: 2px;
    }

    &__key {
      color: var(--text-color-default);
    }

    &__value {
      color: #000000;
    }
  }

  .mapboxgl-popup-content {
    background-color: var(--background-color-default);
    color: var(--text-color-main);
    box-shadow: var(--box-shadow-popup);
    padding: 10px 10px;
    box-sizing: border-box;
  }
}
