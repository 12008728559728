.sector-analysis-filter {
  padding: 16px 16px 13px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__days {
    position: relative;
    width: 127px;
    height: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  &__multi {
    position: absolute;
    top: 6px;
    z-index: 99;
  }

  &__button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--text-color-default);
    fill: var(--icon-color-default);
    padding-left: 28px;

    &:hover {
      color: var(--text-color-hover);
      fill: var(--icon-color-hover);
    }

    &-label {
      font-size: 12px;
      line-height: 1.75;
      color: inherit;
      margin-right: 14px;
      width: 55px;
    }

    & > svg {
      position: relative;
      transform: rotate(270deg);
    }

    &_compare {
      & .sector-analysis-filter__button-label {
        margin-right: 0;
        margin-left: 14px;
      }

      & > svg {
        transform: rotate(90deg);
      }
    }
  }

  &-show-event {
    padding-left: 10px;

    .route-checkbox-container {
      padding-left: 24px;
    }

    .route-checkbox-container .route-checkbox-checkmark::after {
      width: 2px;
      height: 10px;
    }

    .route-checkbox-label {
      font-size: 12px;
      line-height: 1.5;
      color: var(--text-color-default);

      &:hover {
        color: var(--text-color-hover);
      }
    }

    .route-checkbox-checkmark {
      width: 16px;
      height: 16px;
      top: 0;
    }
  }

  .filter-external-system-popup {
    margin-top: 1px;
  }

  .filter-external-system-content {
    height: 32px;
    width: 260px;

    .filter-external-system-popup {
      font-size: 12px;
      box-shadow: none;
      border: solid 1px var(--border-color-default);
      border-radius: 4px;
    }

    .external-system-item-name {
      font-size: 12px;
    }

    .filter-external-system-popup-visible {
      height: 360px;
    }

    .external-system-icon svg {
      width: 20px;
      height: 20px;
    }

    .external-system-item {
      padding: 5px 10px;

      .external-system-icon {
        margin-right: 4px;
      }
    }
  }

  &__date {
    display: flex;

    &_disabled {
      opacity: 0.5;

      & > * :hover {
        cursor: default;
      }
    }
  }
}

.rpc {
  &.sector-analysis-filter__datepicker {
    --rpc__line-height: 30px;

    z-index: 5;

    .rpc {
      &__label {
        background-color: var(--background-color-default);
        border-color: var(--border-color-default);

        &__decorator-before {
          svg {
            width: 24px;
            height: 24px;
          }
        }

        &__toggle {
          &-value {
            margin-top: 4px;
            margin-right: 7px;
            min-width: unset;

            > span {
              font-size: 12px;
            }
          }

          &-from {
            margin-left: 0;
          }

          &-to {
            margin-right: 0;
          }

          &-btn {
            &:focus {
              box-shadow: none;
            }
          }

          &-hyphen {
            background-color: var(--text-color-default);
          }
        }
      }

      &__header-nav {
        box-shadow: none;
      }

      &__days {
        &__btn {
          box-shadow: none;
        }
      }

      &__area {
        left: 0;
        transform: translate(0, var(--rpc__area__transform-y));
      }
    }
  }
}
