.travel-heatmap-settings {
  position: absolute;
  top: 0;
  transform: translateY(36.5%);
  right: 16px;
  display: flex;
  flex-direction: column;
  width: 289px;
  gap: 15px;
  background-color: var(--background-color-default);
  box-shadow: var(--box-shadow-popup);
  border-radius: 3px;
  padding: 16px;

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
  }

  .rpc {
    width: 100%;
    &__label {
      width: 100%;

      &__toggle {
        width: 100%;

        &-hyphen {
          background-color: var(--text-color-default);
        }

        &-btn {
          display: flex;
          justify-content: center;
          width: 100%;
          padding-left: 4px;
          color: var(--text-color-default);
        }
      }
    }

    &__area {
      z-index: 1;
    }
  }
}
