.one-day-selector {
  &__container {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_disabled {
      opacity: 0.5;

      & * > :hover {
        cursor: default;
      }
    }
  }

  &__button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    cursor: pointer;
    fill: var(--icon-color-default);

    & > svg {
      fill: inherit;
    }

    &:hover {
      fill: var(--icon-color-hover);
    }

    &_left {
      transform: rotate(180deg);
    }

    &_right {
      transform: rotate(0deg);
    }

    &_disabled {
      fill: var(--icon-color-disabled);
      cursor: default;

      &:hover {
        fill: var(--icon-color-disabled);
      }
    }
  }

  &__info {
    max-width: 74px;
    background-color: #ffffff;
    color: var(--text-color-default);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    font-size: 12px;
    font-weight: normal;
    line-height: 1.5;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  &__btn {
    text-align: center;
  }

  &__select-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;

    &__select {
      text-align: center;
      text-align-last: center;

      overflow: hidden;

      option {
        background-color: #ffffff;
        color: #000000;
        text-align: center;

        &:hover {
          background-color: #1e90ff;
          color: #ffffff;
        }
      }
    }

    &__label {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}
