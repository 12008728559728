.zoom-control {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 40px;
  height: 80px;
  padding: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.102);
  border-radius: 50px;

  button {
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    background-color: #ffffff;

    svg {
      fill: var(--text-color-default);
    }

    &:hover {
      cursor: pointer;
      svg {
        fill: var(--text-color-hover);
      }
    }

    &:active {
      svg {
        opacity: 1;
        fill: var(--border-color-active);
      }
    }
  }

  &__divider {
    width: 20px;
    height: 1px;
    background-color: #000000;
    opacity: 0.1;
  }
}
