.detector-tooltip-popup {
  .mapboxgl-popup-content {
    display: flex;
    width: auto;
    height: auto;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}
