.megapolis-settings {
  &__container {
    position: relative;
    display: flex;
  }

  &__button {
    padding: 0;
    margin: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    outline: none;
    background-color: transparent;

    svg {
      fill: grey;
    }

    &_active {
      background-color: #62c400;
      svg {
        fill: #ffffff;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
