.route-difference-time {
  font-weight: 500;

  &-more {
    color: var(--green-color);
  }

  &-less {
    color: var(--orange-color);
  }
}
