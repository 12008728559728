.app-tooltip {
  --app-tooltip-wrapper-width: unset;
  --app-tooltip-wrapper-height: unset;

  display: flex;
  align-items: center;
  width: var(--app-tooltip-wrapper-width);
  height: var(--app-tooltip-wrapper-height);
  padding: 3px 5px;
  background-color: #fff2af;
  border-radius: 5px;

  &_centered {
    display: grid;
    place-items: center;

    .app-tooltip__label {
      max-width: 80%;
      text-align: center;
    }
  }

  &__label {
    display: block;
    width: 100%;
    height: auto;
    color: var(--text-color-default);

    font-size: 9pt;
    font-weight: 500;
  }

  &__btn {
    border: none;
    outline: none;
    background-color: transparent;
    margin-left: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}
