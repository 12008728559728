.sector-analysis-appearance-button {
  border: none;
  outline: none;
  background-color: #ffffff;
  border: solid 1px transparent;
  border-radius: 15px;
  padding: 4px 8px;

  &__title {
    font-size: 12px;
    color: var(--text-color-default);
  }

  &:disabled {
    border: none;

    span {
      color: #e5e5e5;
    }

    &:hover {
      span {
        color: #e5e5e5;
      }
    }

    &:active {
      span {
        color: #e5e5e5;
      }
    }
  }

  &:hover {
    border-color: var(--border-color-main);
    cursor: pointer;

    span {
      color: var(--text-color-hover);
    }
  }

  &:active {
    border-color: var(--background-color-active);

    span {
      color: var(--green-color);
    }
  }
}
