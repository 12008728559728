.number-of-edges-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.number-of-edges {
  width: 110px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0 10px;
}

.icon-block {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.decription {
  color: #696969;
  font-size: 14px;
}
