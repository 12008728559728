#root {
  overflow: hidden;
}

.app {
  position: relative;
  min-height: 350px;
  min-width: 950px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &-map {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__compass {
    position: absolute;
    top: 50%;
    transform: translateY(365%);
    right: 16px;
  }

  &__zoom {
    position: absolute;
    top: 50%;
    transform: translateY(265%);
    right: 16px;
  }

  .dtm-mapbox-popup {
    .mapboxgl-popup-content {
      padding: 0;
    }
  }
}

.user-auth {
  position: absolute;
  top: 16px;
  right: 16px;
}

:root {
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #c4c4c4;
    border-radius: 5px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--text-color-default);
    min-height: 50px;
    min-width: 50px;
  }
}
