.body-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 15px;
}

.body__grid {
  display: grid;
  grid-template-columns: 188px repeat(3, 100px) auto;
  gap: 8px;
  text-align: right;

  * {
    font-size: 12px;
  }

  &-header {
    color: var(--text-color-second);
  }
}

.title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__add-button {
    width: 16px;
    height: 16px;
    border: none;
    outline: none;
    background-color: #ffffff;

    svg {
      fill: var(--text-color-disabled);
      width: 24px;
      transform: translate(-4px, -4px);
    }

    &:hover {
      cursor: pointer;

      svg {
        fill: var(--border-color-active);
      }
    }
  }
}

.versions-block {
  display: flex;
  gap: 25px;
  padding-bottom: 5px;
}

.version-block {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__title {
    font-size: 12px;
    color: var(--text-color-default);
  }
  &__datepicker-block {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.statistic-blocks {
  display: flex;
  justify-content: space-between;
}

.statistic-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;

  &_loader {
    display: flex;
    padding-top: 20px;
    align-self: center;
    justify-self: center;
    align-content: center;
    height: 100%;
    contain: size;

    div {
      position: static;
      margin-left: auto;
      margin-right: 0;
    }
  }

  &__value-number {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

    line-height: 32px;
    color: var(--text-color-main);
    font-weight: 500;
    font-size: 12px;

    &.green,
    &.green .statistic-block__diff {
      color: #32d74b;
    }

    &.red,
    &.red .statistic-block__diff {
      color: tomato;
    }
  }

  &__actions {
    button {
      margin-left: auto;
    }
  }

  &__diff {
    font-weight: 400;
    font-size: 11px;
    color: var(--text-color-second);
  }

  &__value-date {
    font-weight: 400;
    font-size: 12px;
    color: var(--text-color-default);
  }

  &__eye {
    background-color: transparent !important;
    svg {
      fill: var(--separator-color-default);
    }

    &:hover {
      svg {
        fill: var(--icon-color-default);
      }
    }

    &.selected {
      svg {
        fill: var(--green-main);
      }
    }
  }
}

.disable {
  svg {
    fill: var(--separator-color-default);
  }

  &:hover {
    svg {
      fill: var(--separator-color-default);
    }
    cursor: default;
  }
}
