.route-traffic-distribution {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  &-point {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: -2px;
  }

  &-start-point {
    background-color: #fd1c00;
  }

  &-end-point {
    background-color: #00b8fc;
    right: 0;
  }

  &-substrate {
    height: 4px;
    width: 100%;
    background-color: #e2e2e2;
    position: relative;
    border-radius: 2px;
  }

  &-distribute {
    display: flex;
    position: relative;
  }
}
