.toggle-button {
  display: flex;
  width: 100%;
  height: 28px;
  gap: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 100px;

  &__button {
    display: flex;
    align-items: center;
    gap: 6px;
    width: 50%;
    height: 100%;
    border: none;
    background-color: #ffffff;
    border-radius: 100px;
    padding: 0 4px;
    outline: none;
    color: var(--text-color-default);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    &_fill {
      justify-content: center;
    }

    svg {
      fill: #e0e0e0 !important;
      stroke: #e0e0e0 !important;
    }

    &_selected {
      outline: 1px solid #62c400 !important;

      &_fill {
        color: #ffffff;
        background-color: #62c400;
      }

      svg {
        fill: #62c400 !important;
        stroke: #62c400 !important;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
