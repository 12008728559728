.sector-analysis-card {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-basis: 200px;
  flex-grow: 0;

  &-header {
    display: flex;
    padding-bottom: 12px;
    align-items: center;

    & > svg {
      color: var(--icon-color-default);
      fill: var(--icon-color-default);
      width: 20px;
      height: 20px;
    }
  }

  &-title {
    display: flex;
    flex-direction: row;
    margin-left: 12px;
    font-size: 12px;
    color: var(--text-color-main);
    line-height: 1.5;
  }

  &-row {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
  }

  &-name {
    color: var(--text-color-default);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
  }

  &-value {
    color: var(--text-color-main);
    line-height: 1.5;
    font-size: 12px;
    font-weight: 500;
  }

  &-average {
    color: var(--text-color-main);
    line-height: 1.5;
    font-size: 12px;
    font-weight: 500;
  }

  &-deviation {
    margin-left: 4px;
    font-size: 12px;
    line-height: 1.5;
    color: var(--text-color-default);
  }

  &-range {
    font-size: 12px;
    line-height: 1.5;
    color: var(--text-color-default);
  }

  &-routes {
    display: flex;
  }

  &-route {
    &:not(:first-child) {
      margin-left: 5px;
    }
  }
}
