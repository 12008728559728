.route-date-picker {
  position: relative;

  &-calendar {
    --rpc__line-height: 30px;

    z-index: 5;
    width: 180px;
    height: 32px;

    .rpc__months__btn,
    .rpc__days__btn,
    .rpc__header-title,
    .rpc__header-nav,
    .rpc__label__toggle-btn {
      &:focus {
        box-shadow: none !important;
      }
    }

    .rpc {
      &__label {
        background-color: transparent;
        border-color: var(--border-color-default);
        width: 100%;
        height: 100%;

        &__decorator-before {
          svg {
            width: 24px;
            height: 24px;
          }
        }

        &__toggle {
          &-value {
            margin-right: 7px;
            min-width: unset;
            color: var(--text-color-default);

            > span {
              font-size: 12px;
            }
          }

          &-from {
            margin-left: 0;
          }

          &-to {
            margin-right: 0;
          }

          &-btn {
            &:focus {
              box-shadow: none;
            }
          }

          &-hyphen {
            background-color: var(--text-color-default);
          }
        }
      }

      &__area {
        left: -90px;
        top: 40px;
        transform: translate(0, var(--rpc__area__transform-y));
      }

      &__title {
        height: 100%;
        width: 100%;
      }
    }
  }

  &-buttons {
    position: absolute;
    top: -1px;
    right: 5px;
    display: flex;
    height: 32px;
    align-items: center;
    width: 50px;
    align-items: center;
    justify-content: space-around;
    z-index: 10;
  }

  &-button {
    background-color: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }

    & > i {
      font-size: 7px;
      color: var(--text-color-default);
    }

    &-right {
      transform: rotate(270deg);
    }

    &-left {
      transform: rotate(90deg);
    }
  }
}
