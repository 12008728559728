.route-button-analysis {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--text-color-default);
  fill: var(--icon-color-default);
  border: solid 1px transparent;
  border-radius: 15px;
  padding: 4px 10px;
  position: relative;
  left: 5px;

  &:hover {
    color: var(--text-color-hover);
    fill: var(--icon-color-hover);
    border-color: var(--border-color-main);
  }

  &-title {
    font-size: 12px;
    font-weight: 500;
    color: inherit;
    margin-right: 4px;
  }

  &-disabled {
    color: var(--text-color-disabled);
    fill: var(--icon-color-disabled);
    cursor: default;

    &:hover {
      color: var(--text-color-disabled);
      fill: var(--icon-color-disabled);
      border-color: transparent;
    }
  }

  &-active {
    color: var(--green-color);
    fill: var(--green-color);
    border-color: var(--background-color-active);
    cursor: pointer;

    &:hover {
      color: var(--green-color);
      fill: var(--green-color);
      border-color: var(--background-color-active);
    }
  }

  & > svg {
    position: relative;
    top: -1px;
    fill: inherit;
    width: 20px;
    height: 20px;

    .prefix__cls-2 {
      fill: inherit;
    }
  }
}
